.filterInputLabel {
  margin: 0 10px 10px 0;
}

.mainContainer {
  display: flex;
}

.tableContainer {
  flex: 1;
}

.deviceLabel {
  margin: 15px 0;
}

.measurementsTable {
  table-layout: fixed;
  width: 100%;
  border: 1px solid black;
  background: #e6f8ff;
  th,
  td {
    text-align: center;
    border: 1px solid black;
  }
  border-collapse: collapse;

  td {
    height: 36px;
  }
}

.tableInputContainer {
  input {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 0;
    background: white;
  }
}

.measurementsContainer {
  display: flex;
  column-gap: 1px;
}

.success,
.fail,
.pending {
  font-size: 24px;
}

.success {
  color: green;
}
.fail {
  color: red;
}
.pending {
  color: black;
}

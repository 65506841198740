.mainContainer {
  padding: 32px;
}
.formContainer {
  padding: 8px;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 8px;
}
.formHeader {
  font-size: 48px;
  text-align: center;
  margin: 0;
}

.submitButton {
  margin-top: 16px;
}

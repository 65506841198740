.mainContainer {
  padding: 4px;
  display: grid;
  grid-template-columns: repeat(4, auto 1fr);
  column-gap: 8px;
  row-gap: 2px;
  justify-items: end;
  align-items: center;
}

.anchorLabel {
  margin: 0;
  font-size: 20px;
  color: #b31466;
  padding-right: 16px;
}

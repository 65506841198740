.mainContainer {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
}

.controlsContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 16px;
}

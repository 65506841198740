.solverResultsGrid {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 16px;
  row-gap: 10px;
  align-items: center;
}

.solverNote {
  font-size: 20px;
  color: #dd0f5c;
  text-align: center;
}

.checkLabel {
  margin: 0;
}

.mainContainer {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 8px;
}

.submitButtonContainer {
  align-self: flex-end;
}

$theme-colors: (
  "primary": #9a82c6,
  "secondary": #676a6cff,
);

@import "~bootstrap/scss/bootstrap";

.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(103, 106, 108);
  padding-bottom: 30px;
}

.groupContainer {
  border: 1px solid grey;
  border-radius: 8px;
  padding: 8px;
  background: linear-gradient(to bottom, #c0dfff, #89b6da);
  color: #3f3f3f;
}

.groupLabel {
  margin-bottom: 10px;
}

.staticDataContainer {
  font-size: 20px;
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.selectFormLabel {
  font-size: 16px;
}

.warningMessage {
  font-size: 20px;
  color: orangered;
}

.locationsGrid {
  display: grid;
  row-gap: 1px;
  column-gap: 1px;
}

.cardContainer,
.cardContainerFilledOut {
  color: black;
  padding: 16px 24px;
  box-shadow: #606060 1px 2px 4px 1px;
  display: flex;
  justify-content: space-between;
}
.cardContainer {
  background: linear-gradient(to bottom, #9dcaf2, #c7e6ff);
}
.cardContainerFilledOut {
  cursor: pointer;
  background: linear-gradient(to bottom, #9af3a0, #c7ffce);
}

.contentContainer {
  flex: 1;
}

.editIcon {
  display: none;
}
.cardContainerFilledOut:hover {
  background: linear-gradient(to bottom, #8af190, #b3f5bc);
  .editIcon {
    display: block;
  }
}

.headline {
  text-align: left;
  margin: 0;
  font-size: 24px;
  padding-bottom: 2px;
}

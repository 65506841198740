.anchorsSectionContainer {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
}

.anchorsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.anchorLabel {
  margin: 0;
  font-size: 20px;
  text-align: left;
  color: #b31466;
  padding-bottom: 4px;
}

.anchorContainer {
  padding: 8px;
}

.formGrid {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  column-gap: 4px;
  row-gap: 2px;
  justify-items: end;
}

.formLabel {
  font-size: 16px;
  display: flex;
  column-gap: 4px;
}
.formInput {
  width: 100%;
}
.plotContainer {
  padding-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}
